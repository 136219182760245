
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































































































































































































































































































.flexible-slider {
  overflow: hidden;
}

.flexible-slider-inner {
  border-top: 1px solid $c-light-grey;
}

.flexible-slider-container {
  @include default-margin(0.5);

  position: relative;
  height: 30rem;

  @include mq(l) {
    height: 58rem;
  }
}

.flexible-slider__items {
  @extend %list-nostyle;
  @include center-y;

  left: 0;
  display: flex;
  width: max-content;
  height: 100%;
}

.flexible-slider__item {
  height: 100%;
  padding-right: $spacing * 2;

  &:last-child {
    padding-right: 0;
  }

  &.has-link {
    position: relative;
    text-decoration: none;
    transform: translateX(0);
  }
}

.flexible-slider__item__link {
  @extend %ff-alt;

  position: absolute;
  top: calc(100% - #{$spacing * 3});
  display: flex;
  text-decoration: none;

  .icon {
    flex-shrink: 0;
    height: 3rem;
    color: $c-mustard;
  }

  &::after {
    @include get-all-space;

    content: '';
    position: fixed;
    z-index: 1;
    opacity: 0.1;
  }
}

.flexible-slider__item__picture {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: $spacing * 2;

  &.picture {
    width: fit-content;
  }

  ::v-deep .picture__container {
    flex-grow: 1;
    width: fit-content;
    height: 100%;
    padding-bottom: 0;
  }

  ::v-deep img {
    width: auto;
    max-width: none;
    height: 100%;
  }

  ::v-deep .picture__caption {
    position: absolute;
    top: calc(100% - calc(#{$spacing} * 2));
    line-height: 2rem;
  }

  a + & {
    padding-bottom: $spacing * 3;
  }

  a:hover + &,
  a:focus-visible & {
    ::v-deep img {
      transform: scale(1.02);
    }
  }
}

.flexible-slider__controls {
  display: flex;
  justify-content: flex-start;
  gap: $spacing * 0.5;
}

[class*='flexible-slider__controls__item--'] {
  &[class*='--prev'],
  &[class*='--next'] {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    width: 4.6rem;
    height: 4.6rem;
    padding: 0;
  }
}

.flexible-slider__controls__item--link {
  display: inline-block;
  width: fit-content;
  text-align: center;
}
